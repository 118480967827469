import React, { useEffect, useState } from "react";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { NavLink, useNavigate } from "react-router-dom";
import Footer from "../../component/Footer";
import "../../assets/new/css/Forum.css";
import "../../assets/new/css/MyDiary.css";
import "../../assets/new/css/SchoolTvChannel.css";
import "../../assets/new/css/library.css";
import "../../assets/new/css/wallet.css";
import schooltrack1 from "../../assets/new/images/schoolbus/schooltrack1svg.svg";
import schooltrack2 from "../../assets/new/images/schoolbus/schooltrack2.svg";
import schooltrack3 from "../../assets/new/images/schoolbus/schooltrack3.svg";
import { simplePostCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Params from "../../config/Params";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const TrackSchoolBus = () => {
  const [tracking, setTracking] = useState(false);
  const [trackingMessage, setTrackingMessage] = useState("");
  const navig = useNavigate();
  useEffect(() => {
    trackingData();
  }, []);
  console.log(tracking, "hgggftud");
  const trackingData = () => {
    let requestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      student_id: Params.student_id,
    });
    simplePostCall(ApiConfig.LIVE_TRACK, requestBody).then((data) => {
      // console.log("dataaads", data),
      if (data.result == true) {
        setTracking(true);
      } else {
        toast.success(data.message);

        console.log(data.message, "message");
        setTrackingMessage(data.message);
      }
    });
  };
  const handleNavigation = () => {};
  const listData = [
    {
      id: 1,
      image: schooltrack1,
      msg: "This is used to track the vehicle",
      heading: "Tracking",
      nav:
        //  tracking == false ? "/TrackSchoolBus" :
        "/StuTracking",
    },
    {
      id: 2,
      image: schooltrack2,
      msg: "This displays the route and its details",
      heading: "Route",
      nav: "/RouteMap",
    },
    {
      id: 3,
      image: schooltrack3,
      msg: "This contains the authorized persons of the student",
      heading: "Authorised Persons",
      nav: "/AuthorizedPerson",
    },
  ];
  return (
    <>
      <div className="page-wrapper compact-wrapper" id="pageWrapper">
        <div className="page-body-wrapper sidebar-icon">
          <div className="page-body" id="container">
            <div class="Forum library">
              <div>
                <div className="row">
                  {listData.map((item, index) => (
                    <div className="col-md-4" key={index}>
                      <a
                        className=""
                        href={item.nav}
                        style={{
                          // color: "transparent",
                          textDecoration: "none",
                          backgroundColor: " transparent",
                        }}
                      >
                        <div className="d-flex flex-row ps-4 pe-1 py-2 align-items-center my-1 rounded-4 shadow-sm schollbuscard">
                          <div>
                            <img src={item.image} alt="" />
                          </div>

                          <div className="d-flex flex-column  ps-2 ">
                            <label
                              className="track_title"
                              style={{
                                color: "#7836a2",
                                fontSize: "14px",
                              }}
                            >
                              {item.heading}
                            </label>
                            <small
                              className="feed-title"
                              style={{
                                color: "#857E84",
                                fontSize: "12px",
                              }}
                            >
                              {item.msg}
                            </small>
                          </div>
                        </div>
                      </a>
                    </div>
                  ))}
                  <ToastContainer />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default TrackSchoolBus;
