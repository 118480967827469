import React, { useEffect, useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Link } from "react-router-dom";
import Footer from "../../component/Footer";

import "../../assets/new/css/MyDiary.css";
import "../../assets/new/css/SchoolTvChannel.css";
import "../../assets/new/css/library.css";
import "../../assets/new/css/wallet.css";
import { withTranslation } from "react-i18next";
import Params from "../../config/Params";
import { simplePostCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import InfiniteScroll from "react-infinite-scroll-component";

const CashlessWallet = () => {
  const [walletData, setWalletData] = useState([]);
  const [usd, setUsd] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endtDate, setEndDate] = useState("");
  const [selectedRange, setSelectedRange] = useState("");
  const [startDate1, setStartDate1] = useState(null);
  const [endDate1, setEndDate1] = useState(new Date());
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState("");
  const [messages, setMessages] = useState("");

  const WalletCash = (pages) => {
    let RequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      student_id: Params.student_id,

      page: pages,
      limit: "10",
    });
    simplePostCall(ApiConfig.WALLET_DATAS, RequestBody)
      .then((data) => {
        let totals = data.next_page;
        setTotal(totals);

        if (data.result == true) {
          setWalletData((prevdata) => [
            ...prevdata,
            ...data.transaction_statements,
          ]);

          setUsd(data.payment_details);
        } else {
          setMessages(data.message);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };
  // <----------------------------------->// <----------------------------------->
  const onPageLoad = () => {
    if (total !== false) {
      setPage(page + 1);
      let a = page + 1;
      WalletCash(a);
    }
  };

  //<--------------------------------->
  console.log(walletData, "wallet1.0");
  // useEffect(() => {
  //   walletCheck();
  // }, [startDate1, endDate1]);
  // useEffect(() => {
  //   WalletCash(total + 1);
  // }, []);

  useEffect(() => {
    WalletCash(1); // Initially load the first page of data
  }, []);

  useEffect(() => {
    if (startDate1 && endDate1) {
      walletCheck(); // Call walletCheck when startDate1 and endDate1 are updated
    }
  }, [startDate1, endDate1]);

  const walletFilter = () => {
    let RequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      student_id: Params.student_id,
      start_date: startDate,
      end_date: endtDate,
    });
    simplePostCall(ApiConfig.WALLET_DATAS, RequestBody)
      .then((data) => {
        console.log(data, "yyyyyyyyyy////...");
        if (data.result == true) {
          console.log(data.transaction_statements, "staements");
          setWalletData(data.transaction_statements);
        } else {
          console.log(data.message, "joiouiuy");

          setMessages(data.message);
        }
      })
      .catch((error) => console.log(error, "error"));
  };
  console.log(messages, "messsss");

  const walletCheck = () => {
    let RequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      student_id: Params.student_id,
      start_date: startDate1,
      end_date: endDate1,
    });
    simplePostCall(ApiConfig.WALLET_DATAS, RequestBody)
      .then((data) => {
        console.log(data, "ggiuupkkk");
        if (data.result == true) {
          console.log(data.transaction_statements, "set");
          setWalletData(data.transaction_statements);
        } else {
          console.log(data.message, "joiouiuy");
          setWalletData([]);
          setMessages(data.message);
        }
      })
      .catch((error) => console.log(error, "error"));
  };

  const handleRadioChange = (range) => {
    setSelectedRange(range);
    let newStartDate = new Date(); // Start with current date

    // Calculate the start date based on the selected range
    switch (range) {
      case "lastMonth":
        newStartDate.setMonth(newStartDate.getMonth() - 1);
        newStartDate.setDate(1); // First day of the previous month
        break;
      case "last3Months":
        newStartDate.setMonth(newStartDate.getMonth() - 3); // 3 months ago
        break;
      case "last6Months":
        newStartDate.setMonth(newStartDate.getMonth() - 6); // 6 months ago
        break;
      case "lastYear":
        newStartDate.setFullYear(newStartDate.getFullYear() - 1); // 1 year ago
        newStartDate.setMonth(0); // January
        newStartDate.setDate(1); // First day of the year
        break;
      default:
        newStartDate = new Date(); // Default to today if no range is selected
        break;
    }

    const formatDate = (date) => {
      return `${date.getFullYear()}/${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}/${date.getDate().toString().padStart(2, "0")}`;
    };

    // Set the calculated startDate1 and the current endDate1 (current date)
    setStartDate1(formatDate(newStartDate));
    console.log("startDate1: ", formatDate(newStartDate)); // Debugging the calculated start date
    setEndDate1(formatDate(new Date()));

    // walletCheck();
  };

  return (
    <>
      <div className="page-wrapper compact-wrapper" id="pageWrapper">
        <div className="page-body-wrapper sidebar-icon">
          <div className="page-body" id="container">
            <div class="library">
              <div>
                <div className="row">
                  <div>
                    <div className="col-md-6">
                      <label className="Balance">Balance</label>
                    </div>
                    <div className="col-md-6">
                      <label className="walletcash">{usd.currency_code}</label>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="books-main-wrapper">
                      <TabView>
                        <TabPanel
                          header="Last 10 Transactions"
                          className="tabheadstyle"
                          headerStyle={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <InfiniteScroll
                            style={{
                              overflow: "hidden",
                            }}
                            dataLength={walletData?.length || 0}
                            next={onPageLoad}
                            hasMore={total !== false}
                            endMessage={
                              <p
                                style={{
                                  textAlign: "center",
                                  marginTop: "20px",
                                  marginBottom: "15px",
                                }}
                              >
                                {walletData.length > 0 ? (
                                  <b></b>
                                ) : (
                                  <b>{messages}</b>
                                )}
                              </p>
                            }
                          >
                            <div className="row">
                              {walletData?.map((item, index) => (
                                <div className="col-md-4" key={index}>
                                  <Link
                                    to={`/TransactionHis?message=${encodeURIComponent(
                                      item.transaction_id,
                                    )}&type=${encodeURIComponent(
                                      item.transaction_type,
                                    )}`}
                                  >
                                    <div className="d-flex justify-content-between flex-row p-2 my-1 rounded-3 shadow-sm walletcard">
                                      <div>
                                        <p className="walletdatetxt">
                                          {item.date}
                                        </p>
                                        <p
                                          style={{
                                            color: "#857E84",
                                            fontSize: "14px",
                                          }}
                                        >
                                          {item.reference_no}
                                        </p>
                                      </div>

                                      <div
                                        style={{
                                          flexDirection: "row",
                                          display: "flex",
                                        }}
                                      >
                                        <p
                                          style={{
                                            color: item.color,
                                            fontSize: "16px",
                                          }}
                                        >
                                          {item.simple}
                                        </p>
                                        <p
                                          className="walletamout"
                                          style={{
                                            color:
                                              item.transaction_type === "credit"
                                                ? "green"
                                                : "red",
                                          }}
                                        >
                                          {item.transaction_type === "credit"
                                            ? "+"
                                            : "-"}
                                          {item.transaction_amount}
                                        </p>
                                      </div>
                                    </div>
                                  </Link>
                                </div>
                              ))}
                            </div>
                          </InfiniteScroll>
                        </TabPanel>

                        <TabPanel
                          header="Detailed Transactions"
                          className="tabheadstyle"
                          headerStyle={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div className="row">
                            <div className="col-md-12">
                              <p className="Lorem-text">
                                For which period do you need a statement?
                              </p>
                            </div>
                            <div class="row">
                              <div class="col-6">
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="exampleRadios"
                                    id="exampleRadios1"
                                    value="lastMonth"
                                    checked={selectedRange === "lastMonth"}
                                    onChange={() =>
                                      handleRadioChange("lastMonth")
                                    }
                                  />
                                  <label
                                    class="form-check-label lastmonth"
                                    for="exampleRadios1"
                                  >
                                    Last Month
                                  </label>
                                </div>
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="exampleRadios"
                                    id="exampleRadios2"
                                    value="last6Months"
                                    checked={selectedRange === "last6Months"}
                                    onChange={() =>
                                      handleRadioChange("last6Months")
                                    }
                                  />
                                  <label
                                    class="form-check-label  lastmonth"
                                    for="exampleRadios2"
                                  >
                                    Last 6 months
                                  </label>
                                </div>
                              </div>
                              <div class="col-6">
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="exampleRadios"
                                    id="exampleRadios3"
                                    value="last3Months"
                                    checked={selectedRange === "last3Months"}
                                    onChange={() =>
                                      handleRadioChange("last3Months")
                                    }
                                  />
                                  <label
                                    class="form-check-label   lastmonth"
                                    for="exampleRadios3  lastmonth"
                                  >
                                    Last 3 Months
                                  </label>
                                </div>
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="exampleRadios"
                                    id="exampleRadios4"
                                    value="lastYear"
                                    checked={selectedRange === "lastYear"}
                                    onChange={() =>
                                      handleRadioChange("lastYear")
                                    }
                                  />
                                  <label
                                    class="form-check-label  lastmonth"
                                    for="exampleRadios4"
                                  >
                                    Last Year
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="mt-3">
                            <p className="Lorem-text">
                              Or select a custom date of your choice
                            </p>
                            <div className="row">
                              <div className="form-group col-md-6  lastmonth">
                                <label for="task-title">From</label>
                                <input
                                  className="datepicker-here form-control digits"
                                  type="date"
                                  data-language="en"
                                  placeholder="MM-DD-YYYY"
                                  data-position="top left"
                                  onChange={(e) => {
                                    setStartDate(e.target.value);
                                    console.log(e.target.value, "startdate");
                                  }}
                                />
                              </div>
                              <div className="form-group col-md-6  lastmonth">
                                <label for="task-title">To</label>
                                <input
                                  className="datepicker-here form-control digits"
                                  type="date"
                                  data-language="en"
                                  placeholder="MM-DD-YYYY"
                                  data-position="top left"
                                  onChange={(e) => {
                                    setEndDate(e.target.value);
                                    console.log(e.target.value, "end date");
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            className="col-12 btnshpro"
                            onClick={walletFilter}
                          >
                            PROCEED
                          </div>
                        </TabPanel>
                      </TabView>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default CashlessWallet;
