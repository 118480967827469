import React, { useEffect, useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Link, useLocation } from "react-router-dom";
import Footer from "../../component/Footer";
import "../../assets/new/css/Forum.css";
import "../../assets/new/css/MyDiary.css";
import "../../assets/new/css/SchoolTvChannel.css";
import "../../assets/new/css/library.css";
import "../../assets/new/css/wallet.css";
import ApiConfig from "../../api/ApiConfig";
import { simplePostCall } from "../../api/ApiServices";
import Params from "../../config/Params";
import { toWords } from "number-to-words";

const TransactionHis = () => {
  const listData = [
    {
      id: 1,
      date: "12-Aug-2023",
      upi: "UPI/3453453454/UPI",
      amt: " $300",
      simple: "+",
      color: "#72A44B",
    },
    {
      id: 2,
      date: "12-Aug-2023",
      upi: "UPI/3453453454/UPI",
      amt: "$80",
      simple: "-",
      color: "red",
    },
    {
      id: 3,
      date: "11-Aug-2023",
      upi: "UPI/3453453454/UPI",
      amt: "$100",
      simple: "+",
      color: "#72A44B",
    },
    {
      id: 4,
      date: "09-Aug-2023",
      upi: "UPI/3453453454/UPI",
      color: "red",
      amt: "$200",
      simple: "-",
      color: "red",
    },
    {
      id: 5,
      date: "09-Aug-2023",
      upi: "UPI/3453453454/UPI",
      amt: "$300",
      simple: "+",
      color: "#72A44B",
    },
    {
      id: 6,
      date: "08-Aug-2023",
      upi: "UPI/3453453454/UPI",
      amt: "$200",
      simple: "+",
      color: "#72A44B",
    },
    {
      id: 7,
      date: "08-Aug-2023",
      upi: "UPI/3453453454/UPI",
      amt: "$450",
      simple: "-",
      color: "red",
    },
    {
      id: 8,
      date: "07-Aug-2023",
      upi: "UPI/3453453454/UPI",
      amt: "$50",
      simple: "+",
      color: "#72A44B",
    },
  ];
  const [recipetData, setRecipetData] = useState([]);
  const [eq, setEq] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalAmountInWords, setTotalAmountInWords] = useState("");
  const [paymentAmountInWords, setPaymentAmountInWords] = useState("");

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get("message");
  const transactions = params.get("type");
  console.log(id, "trans id");
  console.log(transactions, "deb");

  const walletFilterss = () => {
    let RequestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      transaction_id: id,
      transaction_type: transactions,
    });
    simplePostCall(ApiConfig.WALLET_DETAILS, RequestBody)
      .then((data) => {
        console.log(data, "recipetdata");
        setRecipetData(data.data[0]);

        setEq(data.data[0].item_details);

        console.log(recipetData, "first");
      })
      .catch((error) => console.log(error, "error"));
  };
  useEffect(() => {
    walletFilterss();
  }, []);
  console.log(eq, "EQ");
  useEffect(() => {
    if (eq?.length > 0) {
      const total = eq.reduce(
        (sum, item) => sum + parseFloat(item.total_price),
        0,
      );
      setTotalAmount(total.toFixed(2));
      const words = toWords(total);
      const capitalizedWords = words
        .split(" ")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
        )
        .join(" ");
      setTotalAmountInWords(capitalizedWords);
    }
  }, [eq]);
  useEffect(() => {
    if (recipetData?.payment_amount) {
      const words = toWords(recipetData.payment_amount);
      const capitalizedWords = words
        .split(" ")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
        )
        .join(" ");
      setPaymentAmountInWords(capitalizedWords);
    }
  }, [recipetData]);
  // ????
  // const convertNumber = () => {
  //   let result = '';
  //   const number = parseInt(inputValue, 10);

  //   if (isNaN(number)) {
  //     result = 'Please enter a valid number.';
  //   } else {
  //     switch (conversionType) {
  //       case 'decimalToBinary':
  //         result = number.toString(2); // Convert to binary
  //         break;
  //       case 'decimalToHex':
  //         result = number.toString(16); // Convert to hexadecimal
  //         break;
  //       case 'binaryToDecimal':
  //         result = parseInt(inputValue, 2).toString(10); // Convert binary to decimal
  //         break;
  //       case 'hexToDecimal':
  //         result = parseInt(inputValue, 16).toString(10); // Convert hexadecimal to decimal
  //         break;
  //       default:
  //         result = 'Invalid conversion type';
  //     }
  //   }
  // }

  return (
    <>
      <div className="page-wrapper compact-wrapper" id="pageWrapper">
        <div className="page-body-wrapper sidebar-icon">
          <div className="page-body" id="container">
            <div class="Forum library">
              <div className="invoice-outer-wrappers">
                <div className="invoice-inner-wrapper">
                  <table className="heading-table">
                    <tbody>
                      <tr className="left-title">
                        <td className="lt-heading">Receipt</td>
                        <td className="right-invoice-details">
                          <table></table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="invoice-head">
                    <tbody>
                      <tr className="address-row">
                        <td>
                          <table className="address-table">
                            <tbody>
                              <tr>
                                <td>Name: </td>
                                <td>{recipetData.name}</td>
                              </tr>
                              <tr>
                                <td>
                                  {transactions === "credit"
                                    ? "UPI Transaction No:"
                                    : "Bill No:"}
                                </td>
                                <td>
                                  {transactions === "credit"
                                    ? recipetData.payment_gateway_transaction_no
                                    : recipetData.payment_bill_no}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  {transactions === "credit"
                                    ? "Credit Date:"
                                    : "Purchase Date:"}
                                </td>
                                <td>{recipetData.payment_date}</td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td style={{ padding: "0 0 0 10rem" }}>
                          <table className="address-table2">
                            <tbody>
                              <tr>
                                <td>
                                  {transactions === "credit"
                                    ? "Payment Mode:"
                                    : "Operator Name:"}{" "}
                                </td>
                                <td>
                                  {}
                                  {transactions === "credit"
                                    ? recipetData.payment_mode
                                    : recipetData.operator_name}
                                </td>
                              </tr>
                              <tr>
                                <td>Transaction Ref No:</td>
                                <td>
                                  {transactions === "credit"
                                    ? recipetData.payment_reference_no
                                    : recipetData.payment_gateway_transaction_no}
                                  {}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  {transactions === "credit"
                                    ? "Credit Time:"
                                    : "Purchase Time:"}{" "}
                                </td>
                                <td>{recipetData.payment_time}</td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={8}>
                          {transactions !== "credit" && (
                            <table className="particular-table">
                              <thead>
                                <tr>
                                  <th>Sr.No</th>
                                  <th>item</th>
                                  <th>Quantity</th>
                                  <th>Price</th>
                                  <th>Total Price</th>
                                </tr>
                              </thead>

                              <tbody>
                                {eq?.map((item, index) => (
                                  <tr>
                                    <td className="total">{index + 1}</td>
                                    <td>{item.product_name}</td>
                                    <td
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      {item.quantity}
                                    </td>
                                    <td>{item.price}</td>
                                    <td
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      {item.total_price}
                                    </td>
                                  </tr>
                                ))}
                                <tr className="pt-bottom-section">
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td className="ptb-total ptb-border-radius-bottom-left">
                                    Total Amount Paid
                                  </td>
                                  <td
                                    className="ptb-total ptb-border-radius-bottom-right"
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    {transactions === "credit"
                                      ? recipetData.payment_amount
                                      : totalAmount}
                                  </td>
                                </tr>

                                <tr className="invoice_border">
                                  <td></td>
                                </tr>
                              </tbody>
                            </table>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {/* {transactions !== "credit" && ( */}
                          <table className="invoice-bottom-table">
                            <tbody>
                              <tr className="address-row">
                                <td>
                                  <div className="ib-left">
                                    <div className="payment-input-wrapper">
                                      <div className="pi-heading">
                                        Payment Received{" "}
                                        {transactions === "credit"
                                          ? paymentAmountInWords
                                          : totalAmountInWords}
                                        &nbsp;USD Only
                                      </div>
                                      <div className="pi-amount-wrapper">
                                        <div className="pi-text">
                                          Thank You.
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          {/* )} */}
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default TransactionHis;
