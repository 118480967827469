import React, { useEffect, useState } from "react";
import { simplePostCall } from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import Params from "../config/Params";
import {
  Maximize,
  ShoppingCart,
  Bell,
  Grid,
  Activity,
  CheckCircle,
  FileText,
  UserCheck,
  MoreHorizontal,
} from "react-feather";
const Notification = () => {
  const [notificationList, setNotificationList] = useState([]);
  const [notificationCount, setNotificationCount] = useState("");
  useEffect(() => {
    notificationApi();
  }, []);

  const notificationApi = () => {
    let requestBody = JSON.stringify({
      customer_id: Params.customer_id,
      api_key: Params.api_key,
      student_id: Params.student_id,
    });
    simplePostCall(ApiConfig.NOTIFICATION, requestBody)
      .then((data) => {
        if (data) {
          setNotificationList(data?.notifications);
          setNotificationCount(data?.unread_count);
          // console.log(data?.notifications, "ijiohogougug");
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };
  //   console.log(
  //     notificationList.map((item) => item),
  //     "notiii"
  //   );
  // const numOfNotification = notificationList?.length;
  //   console.log(numOfNotification, "uuyiygiu");

  return (
    <div>
      <ul
        className="notification-dropdown onhover-show-div"
        style={{
          overflow: "hidden",
          overflowY: "scroll",
          height: "450px",
          scrollbarWidth: "thin",
          width: "300px",
          height: "300px",
          scrollbarColor: "#c8e077",
        }}
      >
        <li>
          <p className="f-w-700 mb-0">
            You have {notificationCount} Notifications
            <span className="pull-right badge badge-primary badge-pill">
              {notificationCount}
            </span>
          </p>
        </li>
        {notificationList?.map((item) => (
          <li className="noti-primary">
            <div className="media">
              <span className="notification-bg bg-light-primary">
                <i>
                  <Activity />
                </i>
              </span>
              <div className="media-body">
                <p>{item.notification_text}</p>

                {item.message_recent_time != "" ? (
                  <span>{item.message_recent_time}</span>
                ) : (
                  <span>
                    {item.notification_date}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {item.notification_time}
                  </span>
                )}
              </div>
            </div>
          </li>
        ))}
        {/* <li className="noti-secondary">
          <div className="media">
            <span className="notification-bg bg-light-secondary">
              <CheckCircle />
            </span>
            <div className="media-body">
              <p>Order Complete</p>
              <span>1 hour ago</span>
            </div>
          </div>
        </li>
        <li className="noti-success">
          <div className="media">
            <span className="notification-bg bg-light-success">
              <FileText />
            </span>
            <div className="media-body">
              <p>Tickets Generated</p>
              <span>3 hour ago</span>
            </div>
          </div>
        </li>*/}
        <li className="noti-danger">
          <div className="media">
            {/* <span className="notification-bg bg-light-danger">
              <UserCheck />
            </span> */}
            <div className="media-body">
              <p>All view</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default Notification;
