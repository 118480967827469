import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import profileAvatar from "../assets/new/images/profile/avatar.png";
import { Settings } from "react-feather";
import ApiConfig from "../api/ApiConfig";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import authContext from "../layout/GetUserStatus";
import Chat_icon from "../../src/assets/new/images/Sidebar/Chat.svg";
import ToDo_icon from "../../src/assets/new/images/Sidebar/Todoicon.svg";
import NoticeBoard_icon from "../../src/assets/new/images/Sidebar/Noticeboard_icon.svg";
import Mydiary from "../../src/assets/new/images/DiarySidebaricon.svg";
import schooltrack1 from "../assets/new/images/schoolbus/schooltrack1svg.svg";
import Library_icon from "../../src/assets/new/images/Sidebar/library.svg";
import Params from "../config/Params";

class Sidebar extends Component {
  static contextType = authContext;

  constructor(props) {
    super(props);
    this.state = {
      isHovered: false, // Track whether the sidebar is being hovered
    };
    this.sidebarRef = React.createRef(); // Create a reference to the sidebar
  }

  componentDidMount() {
    const sidebar = this.sidebarRef.current;

    // Attach mouse event listeners for mouseenter and mouseleave
    sidebar.addEventListener("mouseenter", this.handleMouseEnter);
    sidebar.addEventListener("mouseleave", this.handleMouseLeave);
  }

  componentWillUnmount() {
    const sidebar = this.sidebarRef.current;

    // Clean up event listeners to avoid memory leaks
    sidebar.removeEventListener("mouseenter", this.handleMouseEnter);
    sidebar.removeEventListener("mouseleave", this.handleMouseLeave);
  }
  handleMouseEnter = () => {
    this.setState({ isHovered: true });
  };

  handleMouseLeave = () => {
    this.setState({ isHovered: false });
  };

  render() {
    const { t } = this.props;
    const { UserProfile, profile } = this.context;

    return (
      <>
        <div className="page-body-wrapper  sidebar-icon">
          <header className="main-nav" id="myID">
            <div
              ref={this.sidebarRef}
              className="sidebarScroll"
              style={{
                overflowY: this.state.isHovered ? "scroll" : "none", // Show scrollbar only when hovered
                scrollbarWidth: this.state.isHovered ? "thin" : "thin", // Make scrollbar visible only when hovered
                scrollbarColor: this.state.isHovered
                  ? "#D6E493 #ffffff"
                  : "transparent transparent", // Adjust scrollbar colors
                // transition:
                //   "overflow 0.3s ease, scrollbar-width 0.3s ease, scrollbar-color 0.3s ease", // Optional smooth transition
                // right: this.state.isHovered ? "50px" : "0px",
                width: "290px", // Fixed width of the sidebar
                paddingRight: "0px",
              }}
            >
              <div>
                <div className="sidebar-user text-center">
                  <a className="setting-primary ">
                    <Link to="/UserProfile">
                      <Settings />
                    </Link>
                  </a>

                  <Link>
                    {/* <img
                className="img-90 rounded-circle"
                src={
                  localStorage.getItem("student_profilepicture")
                    ? ApiConfig.BASE_URL +
                      "/" +
                      localStorage.getItem("student_profilepicture")
                    : profileAvatar
                }
                alt=""
              /> */}
                    {Params.student_Profile ? (
                      <img
                        className="img-90 rounded-circle"
                        style={{ width: "30%", height: "30%" }}
                        src={
                          // UserProfile &&
                          ApiConfig.NODE_URL + "/" + Params.student_Profile
                        }
                        alt=""
                      />
                    ) : (
                      <img
                        className="img-90 rounded-circle"
                        style={{ width: "30%", height: "30%" }}
                        src={
                          // UserProfile &&

                          profileAvatar
                        }
                        alt=""
                      />
                    )}
                  </Link>
                  <a>
                    <h6 className="mt-3 f-14 f-w-600">
                      {localStorage.getItem("student_name")}
                    </h6>
                  </a>
                  <p className="mb-0 font-roboto">
                    {localStorage.getItem("student_email")}
                  </p>
                </div>
                <nav>
                  <div className="main-navbar">
                    <div className="left-arrow" id="left-arrow">
                      <i data-feather="arrow-left"></i>
                    </div>
                    <div id="mainnav">
                      <ul className="nav-menu  edsys-menu custom-scrollbar">
                        <li className="back-btn">
                          <div className="mobile-back text-end">
                            <span>Back</span>
                            <i
                              className="fa fa-angle-right ps-2"
                              aria-hidden="true"
                            ></i>
                          </div>
                        </li>
                        <li>
                          <NavLink
                            to="/student/dashboard"
                            className="nav-link menu-title link-nav"
                          >
                            <div className="menu-ico dashboard-ico">
                              <span>{t("DASHBOARD")}</span>
                            </div>
                          </NavLink>
                        </li>

                        {/* <li>
                      <NavLink
                        to="/student/explore"
                        className="nav-link menu-title link-nav"
                      >
                        <div className="menu-ico explore-ico">
                          <span>EXPLORE</span>
                        </div>
                      </NavLink>
                    </li> */}
                        <li>
                          <NavLink
                            to="e-learning"
                            className="nav-link menu-title link-nav"
                          >
                            <div className="menu-ico e_learning_selected-ico">
                              <span>{t("E - LEARNING")}</span>
                            </div>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/your-activities"
                            className="nav-link menu-title link-nav"
                          >
                            <div className="menu-ico activities-ico">
                              <span>{t("YOUR ACTIVITIES")}</span>
                            </div>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/calendar"
                            className="nav-link menu-title link-nav"
                          >
                            <div className="menu-ico calender-ico">
                              <span>{t("CALENDER")}</span>
                            </div>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/teachers"
                            className="nav-link menu-title link-nav"
                          >
                            <div className="menu-ico teachers_selected-ico">
                              <span>{t("TEACHERS")}</span>
                            </div>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/MyDiary"
                            className="nav-link menu-title link-nav"
                          >
                            <div>
                              <img
                                src={Mydiary}
                                alt=""
                                style={{
                                  height: "25px",
                                  width: "25px",
                                  marginRight: "10px",
                                }}
                              />
                              <span>{t("MY DIARY")}</span>
                            </div>
                          </NavLink>
                        </li>
                        {/* <li>
                          <NavLink
                            to="/Forum"
                            className="nav-link menu-title link-nav"
                          >
                            <div>
                              <img
                                src={ToDo_icon}
                                alt=""
                                style={{
                                  height: "25px",
                                  width: "25px",
                                  marginRight: "10px",
                                }}
                              />
                              <span>{t("FORUM")}</span>
                            </div>
                          </NavLink>
                        </li> */}
                        <li>
                          <NavLink
                            to="/Feed"
                            className="nav-link menu-title link-nav"
                          >
                            <div className="menu-ico teachers_selected-ico">
                              <img
                                src={Chat_icon}
                                alt=""
                                style={{ height: "25px", width: "25px" }}
                              />
                              <span className="ml-2">{t("FEED")}</span>
                            </div>
                          </NavLink>
                        </li>

                        <li>
                          <NavLink
                            to="/NoticeBoard"
                            className="nav-link menu-title link-nav"
                          >
                            <img
                              src={NoticeBoard_icon}
                              alt=""
                              style={{ height: "25px", width: "25px" }}
                            />
                            <span className="ml-2">{t("Notice Board1")}</span>
                          </NavLink>
                        </li>
                        {/* future we will enhance these session */}
                        {/* <li>
                          <NavLink
                            to="/Library"
                            className="nav-link menu-title link-nav"
                          >
                            <img
                              src={Library_icon}
                              alt=""
                              style={{ height: "25px", width: "25px" }}
                            />
                            <span className="ml-2">{t("LIBRARY")}</span>
                          </NavLink>
                        </li> */}
                        {/* <li>
                          <NavLink
                            to="/StudentChat"
                            className="nav-link menu-title link-nav"
                          >
                            <img
                              src={Chat_icon}
                              alt=""
                              style={{ height: "25px", width: "25px" }}
                            />
                            <span className="ml-2">{t("CHAT")}</span>
                          </NavLink>
                        </li> */}

                        {/* <li></li> */}
                        {/* <li>
                          <NavLink
                            to="/ToDo"
                            className="nav-link menu-title link-nav"
                          >
                            <img
                              src={ToDo_icon}
                              alt=""
                              style={{ height: "25px", width: "25px" }}
                            />

                            <span className="ml-2">{t("TO DO LIST")}</span>
                          </NavLink>
                        </li> */}
                        <li>
                          <NavLink
                            to="/CashlessWallet"
                            className="nav-link menu-title link-nav"
                          >
                            <div className="menu-ico dashboard-ico">
                              <span>{t("CASHLESS WALLET")}</span>
                            </div>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/TrackSchoolBus"
                            // to="/DummyMap"
                            className="nav-link menu-title link-nav"
                          >
                            <div>
                              <img
                                src={schooltrack1}
                                alt=""
                                style={{
                                  height: "25px",
                                  width: "25px",
                                  marginRight: "10px",
                                }}
                              />
                              <span>{t("TRACK SCHOOL BUS")}</span>
                            </div>
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                    <div className="right-arrow" id="right-arrow">
                      <i data-feather="arrow-right"></i>
                    </div>
                  </div>
                </nav>
              </div>
              <div className="dateandtime">
                <div className="box">
                  <span id="date-part"></span>
                  <br />
                  <span id="day-part"></span>
                </div>
                <div className=" right">
                  <span id="month-part"></span>
                  <br />
                  <span id="time-part"></span>
                </div>
              </div>
            </div>
          </header>
        </div>
      </>
    );
  }
}
export default withTranslation()(Sidebar);
Sidebar.contextType = authContext;
